import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestCaseActions = () => {
  return (
    <LayoutComponent>
      <h3>More Test Case actions</h3>
      <div>
        <h5>Clone Test Cases</h5>
        <p>
          Cloning a test case duplicates it with its test steps, but without all
          test results, comments, attachments, and defects of the original test
          case.
        </p>
        <ol>
          <li>
            Select the test cases you wish to clone and click the{" "}
            <strong>Clone</strong> button.
          </li>

          <li>
            The cloned test cases will appear in the list with "(CLONE)"
            appended to their titles. You can rename them by editing each test
            case.
          </li>
        </ol>
      </div>
      <br />
      <div>
        <h5>Archive Test Cases</h5>
        <p>
          To keep your test library organized, you can archive test cases that
          are not currently in use. Archiving removes them from the active view
          without deleting their data.
        </p>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            You can restore archived test cases to the active library whenever
            needed.
          </p>
        </div>
        <ol>
          <li>
            Select the test cases you want to archive and click{" "}
            <strong>Archive</strong>.
          </li>

          <li>The selected test cases will be moved to the archive.</li>

          <li>
            Access archived test cases by clicking on{" "}
            <strong>Archived Test Cases</strong> above the test cases table.
          </li>
        </ol>
        <h5>Unarchive Test Cases</h5>
        <ol>
          <li>
            Go to the <strong>Archived Test Cases</strong> section. Select the
            test cases you wish to restore and click <strong>Unarchive</strong>.
          </li>

          <li>
            The selected test cases will be moved back to the active test
            library.
          </li>
        </ol>
      </div>
      <div>
        <h5>Filter Test Cases</h5>
        <p>
          Use filters to quickly find specific test cases based on various
          criteria such as status, priority, author, functional module, or
          technology layer.
        </p>
        <ol>
          <li>
            Click the <strong>Filter</strong> button in the Test Cases view.
          </li>

          <li>A modal will appear with the filter options.</li>
          <li>
            Select your desired filters by status, priority, author, module
            name, or technology layer.
          </li>

          <li>
            Click <strong>Apply</strong> to apply the filter.
          </li>
          <li>
            Applied filters will be shown below the search bar in the Test Cases
            view.
          </li>
        </ol>
      </div>
    </LayoutComponent>
  )
}

export default TestCaseActions
